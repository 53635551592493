<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card card-custom gutter-b example example-compact">
          <div
            class="
              card-header
              d-flex
              align-items-center
              justify-content-between
            "
          >
            <div class="card-title">
              <h3 class="card-label">
                {{ $t('MENU.REPORT.FINANCE_REPORT.PROVODKA') }}
              </h3>
            </div>
            <div class="card-button">
              <v-btn to="/provodka/create" small color="primary"
                >{{ $t('MENU.REPORT.FINANCE_REPORT.PROVODKA') }} +
              </v-btn>
            </div>
          </div>
          <div class="card-body">
            <v-data-table
              loading-text="Malumotlar yuklanmoqda..."
              :loading="isLoading"
              :headers="headers"
              single-select
              @click:row="rowClick"
              item-key="id"
              :items="list"
              no-data-text="Malumotlar topilmadi!"
              hide-default-footer
              class="elevation-1 row-pointer"
            >
              <template v-slot:[`item.action`]="{ item }">
                <Action :index="item"></Action>
              </template>

              <template v-slot:[`item.oper_date`]="{ item }">
                {{ item.oper_date | dateFilter }}
              </template>

              <template v-slot:[`item.amount`]="{ item }">
                {{ item.amount.toLocaleString('zh-ZH') }}
              </template>
            </v-data-table>
            <div class="text-left mt-5">
              <v-pagination
                v-model="currentPage"
                :total-visible="10"
                @input="getPostData(currentPage)"
                :length="Math.ceil(getCount.count / 10)"
              ></v-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import Action from './Action'
export default {
  data() {
    return {
      counter: 0, // count the clicks
      timer: null, // Needs to be specified here so it can be accessed on both clicks
      selectedId: -1,
      currentPage: 1
    }
  },
  created() {
    if (this.$route.query.page !== undefined) {
      this.getPostData(parseInt(this.$route.query.page, 10))
      this.currentPage = parseInt(this.$route.query.page, 10)
    } else {
      this.getPostData(this.currentPage)
    }
  },
  computed: {
    headers() {
      return [
        { text: 'ID', value: 'id' },
        {
          text: this.$t('TABLE_HEADER.CONTRAGENT'),
          value: 'transaction_description.type_statetransfer_name'
        },
        { text: this.$t('TABLE_HEADER.DATE'), value: 'oper_date' },
        { text: this.$t('TABLE_HEADER.AMOUNT'), value: 'amount' },
        { text: 'Debet', value: 'debet_account_code' },
        { text: 'Credit', value: 'credit_account_code' },
        { text: '', value: 'action', sortable: false }
      ]
    },
    list() {
      const data = this.$store.state.requests.provodkaList.results
      return data
    },
    getCount() {
      return this.$store.state.requests.provodkaList
    },
    isLoading() {
      return this.$store.state.requests.isLoading
    }
  },
  methods: {
    rowClick: function (item, row) {
      var self = this
      this.counter++
      if (this.counter == 1) {
        this.timer = setTimeout(function () {
          self.counter = 0
          row.select(true)
          this.selectedId = item.id
        }, 200)
      } else {
        clearTimeout(this.timer)
        self.counter = 0
        row.select(false)
      }
    },
    getPostData(page) {
      if (parseInt(this.$router.currentRoute.query.page, 10) !== page) {
        this.$router.push({
          path: this.$router.currentRoute.path,
          query: { page: page }
        })
      }
      this.$store.dispatch('getProvodkaList', page)
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t('MENU.REPORT.TITLE') },
      { title: this.$t('MENU.REPORT.FINANCE_REPORT.TITLE') },
      { title: this.$t('MENU.REPORT.FINANCE_REPORT.PROVODKA') }
    ])
  },
  components: {
    Action
  }
}
</script>

<style scoped>
.v-application .elevation-1 {
  box-shadow: none !important;
}

.v-data-table /deep/ .v-data-table__wrapper > table > thead > tr > th {
  font-size: 1rem !important;
  color: #000 !important;
  font-weight: 500 !important;
}
table {
  border-collapse: collapse;
  width: 100%;
}
.v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 1rem !important;
  color: #000 !important;
  height: 60px !important;
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow-x: scroll !important;
  white-space: nowrap !important;
  min-height: 300px;
  width: 100%;
}
</style>
